import React from "react"
import "./styles.css"
import { useParams } from "react-router-dom"


const BasicDetails = ({data}) => {
  const {clientId} = useParams();
  return (
    <>
      <div className="head-text">
        <p className="text-start fw-bold fs-5">Basic Details</p>
      </div>
      <div className="Basic-details fw-semibold">
        <div className="text-start">
          {/* <label for="floatingInputGroup1">PAN</label> */}
          <input type="text" className="form-control" disabled id="floatingInputGroup1" value={data?.pan?.panNumber} />
          <label for="floatingInputGroup1">Date Of Birth</label>
          <input type="text" className="form-control" disabled id="floatingInputGroup1" value={data?.pan?.dob}/>
          <label for="floatingInputGroup1">Name as PAN</label>
          <input type="text" className="form-control" disabled id="floatingInputGroup1" value={data?.pan?.name}/>
          <label for="floatingInputGroup1">Name as AADHAR</label>
          <input type="text" className="form-control" disabled id="floatingInputGroup1" value={data?.aadhar?.name ??""}/>
          <label for="floatingInputGroup1">Care of</label>
          <input type="text" className="form-control" disabled id="floatingInputGroup1" value={data?.aadhar?.careOf ??""}/>
          <label for="floatingInputGroup1">Father Name</label>
          <input type="text" className="form-control" disabled id="floatingInputGroup1" value={data?.userInformation?.fatherName ??""}/>
          <label for="floatingInputGroup1">Mother Name</label>
          <input type="text" className="form-control" disabled id="floatingInputGroup1" value={data?.userInformation?.motherName}/>
          <label for="floatingInputGroup1">Gender</label>
          <input type="text" className="form-control" disabled id="floatingInputGroup1" value={data?.pan?.gender ??""}/>
          <label for="floatingInputGroup1">Nationality</label>
          <input type="text" className="form-control" disabled id="floatingInputGroup1" value={data?.userInformation?.nationality ??""}/>
          <label for="floatingInputGroup1">Text Residency Outside India</label>
          <input type="text" className="form-control" disabled id="floatingInputGroup1" value={data?.userInformation?.taxResidence}/>
          <label for="floatingInputGroup1">Email ID</label>
          <input type="text" className="form-control" disabled id="floatingInputGroup1" value={data?.userDetail?.email ?? ""}/>
          <label for="floatingInputGroup1">Mobile Number</label>
          <input type="text" className="form-control" disabled id="floatingInputGroup1" value={data?.userDetail?.phoneNumber}/>
        </div>
      </div>
    </>
  )
}

export default BasicDetails
