import React, { useState, useEffect } from "react";
import useApi from "../../hooks/useApi";
import Loader from "../../components/loader";
import { ToastContainer, toast } from "react-toastify";
import PdfPreview from "../../components/pdfViewer";
import Button from 'react-bootstrap/Button';

const FinancialProof = ({ data, updateRejectData }) => {
  const { loading, postData } = useApi();
  const [remark, setRemark] = useState("");
  const [imageData, setImageData] = useState("");
  const [isRejectButtonDisabled, setRejectButtonDisabled] = useState(false);

  const handleUploadData = async () => {
    if (imageData) {
      try {
        const res = await postData(
          `admin-ekyc/user/upload/document/${data?.userDetail?.phoneNumber}`,
          {
            financialProof: imageData,
          }
        );
        await toast.success("Uploaded successfully");
        setImageData("");
      } catch (err) {
        toast.error("Something went wrong");
      }
    } else {
      toast.error("Please select doc to upload");
    }
  };

  const onChange = (e) => {
    const files = e.target.files;
    const file = files[0];
    getBase64(file, e.target.name);
  };

  const getBase64 = (file, name) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setImageData(reader?.result);
    };
  };

  const handleApiCall = async () => {
    if (remark?.length > 10) {
      try {
        const res = await postData(
          `reject/upload/${data?.userDetail?.phoneNumber}`,
          {
            FINANCIAL_PROOF: remark,
          }
        );
        toast.success("Rejected successfully");
      } catch (err) {
        toast.error("Something went wrong");
      }
    }
  };

  const handleConsole = (e) => {
    e.preventDefault();
    if (remark.trim() === "") {
      alert("Remark field can't be empty");
    } else {
      const newRem = `<b>FINANCIAL PROOF </b> ${remark}`;
      updateRejectData({ FINANCIAL_PROOF: newRem });
      setRejectButtonDisabled(true);
      toast.success("Reject Added")
    }
  };

  useEffect(() => {
    const storedRemark = localStorage.getItem("finentialProof");
    if (storedRemark) {
      setRemark(storedRemark);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("finentialProof", remark);
  }, [remark]);

  const isValidBase64 = (str) => {
    try {
      return atob(btoa(str)) === str;
    } catch {
      return false;
    }
  };

  const renderDocument = () => {
    const documentData = data?.userInformation?.financialProofDocument;

    if (documentData) {
      if (documentData.startsWith("data:image")) {
        return <img src={documentData} alt="" />;
      } else if (documentData.startsWith("data:application/pdf;base64")) {
        return isValidBase64(documentData.replace(/^data:application\/pdf;base64,/, ''))
          ? <PdfPreview base64String={documentData} />
          : "Invalid PDF data";
      } else if (documentData.startsWith("data:application/zip;base64")) {
        return "Invalid File Format";
      } else {
        return "Unsupported file format";
      }
    } else {
      return "Not Available";
    }
  };

  return (
    <div className="border border-2">
      <Loader open={loading} />
      <ToastContainer />
      <div className="head-container">
        <div className="reject-container p-2 text-start">
          <label htmlFor="floatingInputGroup1">
            <b className={`${Boolean(data?.rejectedDocumentDetails?.FINANCIAL_PROOF) ? "error-text" : ""}`}>
              Reject Remark
            </b>
          </label>
          <input
            disabled={Boolean(data?.rejectedDocumentDetails?.FINANCIAL_PROOF)}
            value={data?.rejectedDocumentDetails?.FINANCIAL_PROOF ?? remark}
            onChange={(e) => setRemark(e.target.value)}
            type="text"
            className="form-control reject-input  border-secondary"
            id="floatingInputGroup1"
          />
        </div>
        <div className="d-flex align-items-center">
          <Button onClick={handleConsole}>Reject</Button>{' '}
        </div>
      </div>
      <div className="document-container">
        {renderDocument()}
      </div>
    </div>
  );
};

export default FinancialProof;
