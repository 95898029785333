import React, { useState, useEffect } from "react";
import "./styles.css";
import useApi from "../../hooks/useApi";
import { ToastContainer, toast } from "react-toastify";
import Button from 'react-bootstrap/Button';
import Loader from "../../components/loader";

const ClientImage = ({ data, updateRejectData }) => {
  const [remark, setRemark] = useState("");
  const { loading, error, postData, fetchData } = useApi();
  const [imageData, setImageData] = useState("");
  const [isRejectButtonDisabled, setRejectButtonDisabled] = useState(false);

  const epochTimestamp = +data?.imageAndSignature?.createdAt ?? 0;

  const dateObject = new Date(epochTimestamp);

  const year = dateObject.getFullYear();
  const month = dateObject.getMonth() + 1;
  const day = dateObject.getDate();
  const hours = dateObject.getHours();
  const minutes = dateObject.getMinutes();
  const seconds = dateObject.getSeconds();

  const formattedDate = epochTimestamp
    ? `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`
    : "";
  const formattedTime = epochTimestamp
    ? `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`
    : "";

  const handleUploadData = async () => {
    if (imageData) {
      try {
        const res = await postData(
          `admin-ekyc/user/upload/document/${data?.userDetail?.phoneNumber}`,
          {
            clientImage: imageData,
          }
        );

        toast.success("Uploaded successfully");
        setImageData("");
      } catch (err) {
        toast.error("Something went wrong");
      }
    } else {
      toast.error("Please select doc to upload");
    }
  };

  const onChange = (e) => {
    const files = e.target.files;
    const file = files[0];
    getBase64(file, e.target.name);
  };

  const getBase64 = (file, name) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setImageData(reader.result);
    };
  };

  const handleConsole = (e) => {
    e.preventDefault();
    if (remark.trim() === "") {
      alert("Remark field can't be empty");
    } else {
      const newRem = `<b>CLIENT IMAGE </b> ${remark}`;
      updateRejectData({ IMAGE: newRem });
      setRejectButtonDisabled(true);
      toast.success("Reject Added")
    }
  };

  useEffect(() => {
    const storedRemark = localStorage.getItem("clientImage");
    if (storedRemark) {
      setRemark(storedRemark);
    }
  }, []);

  // Save remark to local storage when it changes
  useEffect(() => {
    localStorage.setItem("clientImage", remark);
  }, [remark]);


  return (
    <div className="client-container border border-2">
      <ToastContainer />
      <Loader open={loading} />
      <div className="head-container">
        <div className="rejectInput-text d-flex m-3">
          <label for="floatingInputGroup1">
            <b
              className={`${Boolean(data?.rejectedDocumentDetails?.IMAGE)
                ? "error-text"
                : ""
                }`}
            >
              Reject Remark
            </b>
          </label>
          <input
            disabled={Boolean(data?.rejectedDocumentDetails?.IMAGE)}
            value={data?.rejectedDocumentDetails?.IMAGE ?? remark}
            onChange={(e) => setRemark(e.target.value)}
            type="text"
            className="form-control reject-input  border-secondary"
            id="floatingInputGroup1"
          />
        </div>
        <div className="d-flex align-items-center">
          <Button onClick={handleConsole} >Reject</Button>{' '}
        </div>
        {/* <div className="input-file p-2 text-start gap-2">
          <label for="floatingInputGroup1">
            <b>
              Upload Client Image <br />
              (file should be less than 4 MB)
            </b>
          </label>
          <input
            type="file"
            name="financialProof"
            onChange={onChange}
            accept=".pdf, .jpeg, .jpg"
          />
          {imageData && (
            <>
              <div className="w-1/4 h-50 p-4">
                <img
                  className="w-full"
                  src={imageData}
                  alt="bank validation proof"
                />
              </div>
              <button
                variant="outlined"
                color="error"
                onClick={() => setImageData("")}
              >
                Cancel
              </button>
            </>
          )}
          <button onClick={handleUploadData} className="btn btn-danger m-auto ">
            Upload
          </button>
        </div> */}
      </div>
      <div className="document-area">
        <div className="clientImg-container">
          <img src={data?.imageAndSignature?.image} alt="" width="70%" />
        </div>
        <div className="client-text ms-5 text-start">
          <b className="clientLocation-txt fs-3">Location</b>
          <hr className="line" />
          <p>
            <b>Longitude : </b>
            <input
              type="text"
              className="clientInput-text"
              value={data.imageAndSignature?.latitude}
            />
          </p>
          <p>
            <b>Latitude : </b>
            <input
              type="text"
              className="clientInput-text"
              value={data.imageAndSignature?.longitude}
            />
          </p>
          <p>
            <b>Address : </b>
            <input
              type="text"
              className="client-address"
              value={data.imageAndSignature?.address}
            />
          </p>

          <p>
            <b>Time : </b>
            <input
              type="text"
              className="clientInput-text"
              value={data.imageAndSignature?.createdAt}
            />
          </p>
          {/* <p>
            <b>Address :</b>
            <input type="text" className="clientInput-text" />{" "}
          </p>
          <p>
            <b>Date Time :</b>
            <input
              value={`${formattedDate} ${formattedTime}`}
              type="text"
              className="clientInput-text"
            />{" "}
          </p> */}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export { ClientImage };
