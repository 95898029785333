import React, { useState } from "react";
import { VERIFICATION_TABS } from "../../constant/MapperConstant";
import { PanInfo } from "../panInfo";
import { ClientImage } from "../clientImage";
import ClientSignature from "../signatureImage";
import AddressProof from "../addressProof";
import BankProof from "../bankProof";
import FinancialProof from "../financialProof";
import Nominee1 from "../nominee1";
import Nominee2 from "../nominee2";
import Nominee3 from "../nominee3";
import { toast } from "react-toastify";
import "./styles.css";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/loader";
const Slider = ({ clientData }) => {
  const [loader, setLoader] = useState(false);
  const navigation = useNavigate();
  const [activeTab, setActiveTab] = useState(VERIFICATION_TABS[0]);
  const [open, setOpen] = useState(false);

  const [rejectData, setRejectData] = useState({
    PAN_CARD: "",
    IMAGE: "",
    SIGNATURE: "",
    BANK_PROOF: "",
    FINANCIAL_PROOF: "",
    NOMINEE_ONE: "",
    NOMINEE_TWO: "",
    NOMINEE_THREE: "",
  });

  const handleOnClick = (item) => {
    setActiveTab(item);
  };

  const handleFinalSubmit = async () => {
    const token = sessionStorage.getItem("authToken");
    // console.log("Reject Remarks:", rejectData);
    const user = clientData.backOfficeDetails.userId;
    console.log("------------------", user);
    try {
      setLoader(true);
      const res = await fetch(
        `https://ekyc.finovo.tech:5556/api/v1/reject/rekyc/send/rejection`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            xuserid: user,
            Authorization: token,
          },
          body: JSON.stringify(rejectData),
        }
      );
      if (res.ok) {
        console.log("Submission successful");
        toast.success("Request Sent Successfully");
        setOpen(false)
      } else {
        console.error("Submission failed");
      }
    } catch (error) {
      console.error("Error during submission:", error);
    } finally {
      setLoader(false);
    }
  };
  const updateRejectData = (newRejectData) => {
    setRejectData((prevRejectData) => ({
      ...prevRejectData,
      ...newRejectData,
    }));
    console.log("--------------", newRejectData);
  };

  const renderActiveComponent = () => {
    switch (activeTab.id) {
      case 1:
        return (
          <PanInfo data={clientData} updateRejectData={updateRejectData} />
        );
      case 2:
        return (
          <ClientImage data={clientData} updateRejectData={updateRejectData} />
        );
      case 3:
        return (
          <ClientSignature
            data={clientData}
            updateRejectData={updateRejectData}
          />
        );
      case 4:
        return (
          <AddressProof data={clientData} updateRejectData={updateRejectData} />
        );
      case 5:
        return (
          <BankProof data={clientData} updateRejectData={updateRejectData} />
        );
      case 6:
        return (
          <FinancialProof
            data={clientData}
            updateRejectData={updateRejectData}
          />
        );
      case 7:
        return (
          <Nominee1 data={clientData} updateRejectData={updateRejectData} />
        );
      case 8:
        return (
          <Nominee2 data={clientData} updateRejectData={updateRejectData} />
        );
      case 9:
        return (
          <Nominee3 data={clientData} updateRejectData={updateRejectData} />
        );
      default:
        return null;
    }
  };
  const OpenPop = () => {
    setOpen(true);
  };

  const hasRejections = Object.values(rejectData).some(value => value);

  return (
    <>
      <div>
        <Loader open={loader} />
        <div className="m-2 d-flex justify-content-end">
          <button
            onClick={() => navigation("/")}
            type="button"
            className="btn btn-primary bg-primary"
          >
            back
          </button>
        </div>
        <div className="d-flex justify-content-between bg-danger p-3 rounded-top-3 overflow-x-auto w-100">
          {VERIFICATION_TABS.map((item) => (
            <li
              className={`slide-tabs ${
                item.id === activeTab.id
                  ? "active-tab text-white bg-primary rounded-pill text-center"
                  : "text-teal-400"
              }`}
              onClick={() => handleOnClick(item)}
              key={item.id}
            >
              {item.text}
            </li>
          ))}
        </div>
        {activeTab && renderActiveComponent()}
      </div>
      <div className="">
        <Button
      
          onClick={OpenPop}
          variant="success"
          className={
            clientData &&
            (clientData.currentStage === "ESIGN_REQUESTED" ||
              clientData.currentStage === "ESIGNED")
              ? "d-none"
              : ""
          }
        >
          Rejected Request Send
        </Button>
      </div>
      {open && (
        <>
          <div className="modal-wrapper">
            
        <div
           className="relative d-flex justify-content-between flex-column align-items-center z-50"
           style={{
             position: "fixed",
             width: "500px",
             height:"30vh",
             top: "50%",
             left: "50%",
             transform: "translate(-50%, -50%)",
             color: "black",
             backgroundColor: "#FD7E14",
             padding: "0.5rem",
             borderRadius: "0.5rem",
             boxShadow:
             "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
            }}
            >
          <button className="cursor-pointer fw-bold border p-2"
          onClick={()=>{setOpen(false)}}
          >X</button>
          <p className="flex justify-center text-center text-2xl ">
            {hasRejections ? (
              Object.entries(rejectData).map(([key, value]) =>
                value ? (
                  <span className="fw-bold fs-4" key={key}>{value.replace(/<\/?b>/g, "")}<br /></span>
                ) : null
              )
            ) : (
              <span className="fs-3"> You didn't reject anything</span>
            )}
          </p>
          <button onClick={handleFinalSubmit} disabled={hasRejections === false ?true:false} className="btn btn-primary">
           Rejection Send
          </button>
          </div>
            </div>
          </>
      )}
    </>
  );
};

export default Slider;
