import React, { useState, useEffect } from "react";
import Pagination from "react-bootstrap/Pagination";
import useApi from "../../hooks/useApi";
import Loader from "../../components/loader";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router-dom";
import PdfPreview from "../../components/pdfViewer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { CiSearch } from "react-icons/ci";
import { FILTER_DATA } from "../../constant/MapperConstant";

const ClientListingClosure = () => {
  const navigation = useNavigate();
  const [load, setLoad] = useState(false);
  const [data, setData] = useState({ totalPage: 1 });
  const [showAadhar, setShowAadhar] = useState(false);
  const [showAudit, setShowAudit] = useState(false);
  const [fullscreen, setFullscreen] = useState(true);
  const [loader, setLoader] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [authToken, setAuthToken] = useState('');
  const [fileName, setFileName] = useState('');
  const [aadharXml, setAadharXml] = useState("");
  const [searchData, setSearchData] = useState({
    search: "",
    page: 1,
    filter: "CLOSURE",
  });
  const { loading, fetchData } = useApi();
  const [base64String, setBase64String] = React.useState("");

  const getClientList = async () => {
    try {
      const fetchedData = await fetchData(
        `closure/get/closure/request?search=${searchData.search}&status=${searchData.filter}&page=${searchData.page}`,
        {},
        process.env.REACT_APP_ADMIN_BASE_URL
      );
      setData(fetchedData.pageDetail);
      setFilteredData(fetchedData?.closure ?? []);
    } catch (e) {
      toast.error("Error while fetching data");
    }
  };

  useEffect(() => {
    getClientList();
  }, [searchData.page, searchData.search, searchData.filter]);

  const getDataTime = (epoch) => {
    const date = new Date(+epoch);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    return (
      year +
      "-" +
      month +
      "-" +
      day +
      " " +
      hours +
      ":" +
      minutes +
      ":" +
      seconds
    );
  };

  const handleApiCalls = async (url, {}, BASE_URL, successMsg, errorMsg) => {
    const baseUrl = process.env.REACT_APP_ADMIN_BACKOFFICE_BASE_URL ?? BASE_URL;
    try {
      const data = await fetchData(url, {}, baseUrl);
      toast.success(successMsg);
      return data;
    } catch (e) {
      toast.error(errorMsg);
    }
  };

  const handleFilter = (value) => {
    if (value === "CLOSURE") {
      setSearchData((prev) => ({ ...prev, page: 1, filter: value }));
    } else if (value === "COMPLETED") {
      setSearchData((prev) => ({ ...prev, page: 1, filter: value }));
    } else if (value === "NONEED") {
      setSearchData((prev) => ({ ...prev, page: 1, filter: value }));
    }
  };

  const handleSearch = (e) => {
    const searchValue = e.target.value?.trim() ?? "";
    if (
      (e.key === "Enter" && searchValue) ||
      (!searchValue && searchData.search)
    ) {
      setSearchData((prev) => ({ ...prev, search: searchValue, page: 1 }));
    }
    e.preventDefault();
  };
  const StatusButton = (row, status) => {
    const authToken = sessionStorage.getItem("authToken");
    const apiUrl = `https://ekyc.finovo.tech:5556/api/v1/closure/update/closure/status?phoneNumber=${row.phoneNumber}&status=${status}`;

    fetch(apiUrl, {
      method: "GET",
      headers: {
        Authorization: authToken,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log("=====", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const ExportButton = (row) => {
    const authToken = sessionStorage.getItem("authToken");
    const apiUrl = `https://ekyc.finovo.tech:5556/api/v1/closure/get/leegality/Export/button?phoneNumber=${row.phoneNumber}`;

    fetch(apiUrl, {
      method: "GET",
      headers: {
        Authorization: authToken,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
       if(data.auditTrailPdf){
        const base64Data = data.auditTrailPdf;
        const binaryData = atob(base64Data)
        const arrayBuffer = new ArrayBuffer(binaryData.length);
        const uint8Array = new Uint8Array(arrayBuffer);
        
        for (let i = 0; i < binaryData.length; i++) {
          uint8Array[i] = binaryData.charCodeAt(i);
        }
      
        // Create a Blob from Uint8Array
        const blob = new Blob([uint8Array], { type: 'application/pdf' });
      
        // Create a download link
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = 'auditTrailPdf.pdf';
      
        // Append the link to the body and trigger the download
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
       }
       if (data.esignedPdf) {
        // Convert the base64 data to a Uint8Array
        const base64Data = data.esignedPdf;
        const binaryData = atob(base64Data);
        const arrayBuffer = new ArrayBuffer(binaryData.length);
        const uint8Array = new Uint8Array(arrayBuffer);
      
        for (let i = 0; i < binaryData.length; i++) {
          uint8Array[i] = binaryData.charCodeAt(i);
        }
      
        // Create a Blob from Uint8Array
        const blob = new Blob([uint8Array], { type: 'application/pdf' });
      
        // Create a download link
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = 'esignedPdf.pdf';
      
        // Append the link to the body and trigger the download
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  // const handleSubmit = async () => {
  //   // const payload = {
  //   //   AuthToken: authToken,
  //   //   fileName: fileName,
  //   // };
  //   const authT = sessionStorage.getItem("authToken");
  //   try {
  //     const response = await fetch('https://ekyc.finovo.tech:5556/api/v1/kyc/push/push', {
  //       method: 'GET', // or 'PUT'
  //       headers: {
  //         'Content-Type': 'application/json',
  //         Authorization: authT,
  //         AuthToken: authToken,
  //         fileName: fileName
          
  //       },
  //       // body: JSON.stringify(payload),
  //     });

  //     if (!response.ok) {
  //       throw new Error(`HTTP error! status: ${response.status}`);
  //     }

  //     const data = await response.json();
  //     console.log(data); // Process your response further
  //   } catch (error) {
  //     console.error('There was an error!', error);
  //   }
  // };


  return (
    <div>
      <Loader open={loading} />
      {base64String ? (
        <Modal
          size="lg"
          dialogClassName="modal-90w modal-90h"
          show={Boolean(base64String)}
          onHide={() => setBase64String("")}
        >
          <PdfPreview base64String={base64String} />
        </Modal>
      ) : (
        <></>
      )}
      <ToastContainer />
      {/* <div style={{ border: '2px solid', borderColor: 'primary', backgroundColor: 'dark' }}>
      <input
        type="text"
        value={authToken}
        onChange={(e) => setAuthToken(e.target.value)}
      />
      <input
        type="text"
        value={fileName}
        onChange={(e) => setFileName(e.target.value)}
      />
      <button onClick={handleSubmit}>Submit</button>
    </div> */}
      <Container fluid>
        <Row>
          <div className="d-flex justify-content-center gap-5 align-items-center">
            <div className="w-25 d-flex">
              <input
                onKeyUpCapture={handleSearch}
                className="w-100 py-2 ps-3 rounded-pill rounded-end-0 border border-end-0"
                active="border-0"
                placeholder="Enter Mobile Number"
              />
              <div className="bg-white pt-1  rounded-pill rounded-start-0">
                <CiSearch className="fs-3 me-3" />
              </div>
            </div>
            <DropdownButton
              variant={searchData.filter ? "success" : "info"}
              className="my-3"
              id="dropdown-basic-button"
              title={searchData?.filter ? searchData?.filter : "Select Filter"}
            >
              {FILTER_DATA.map((item, idx) => (
                <Dropdown.Item
                  key={item.id}
                  onClick={() => handleFilter(item.id)}
                >
                  {item.text}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </div>

          <Col>
            <Table striped bordered hover responsive>
              <thead>
                <tr className="text-center bg-white">
                  <th>S No.</th>
                  <th>Client Code</th>
                  <th>Status</th>
                  <th>Name</th>
                  <th>Pan Number</th>
                  <th>Phone Number</th>
                  <th
                    className={`${
                      searchData.filter === "COMPLETED" ||
                      searchData.filter === "NONEED"
                        ? "d-none"
                        : ""
                    }`}
                  >
                    Complete
                  </th>
                  <th
                    className={`${
                      searchData.filter === "COMPLETED" ||
                      searchData.filter === "NONEED"
                        ? "d-none"
                        : ""
                    }`}
                  >
                    No Need
                  </th>
                  <th>Export</th>
                </tr>
              </thead>
              {filteredData && filteredData.length ? (
                <tbody align="center">
                  {filteredData.map((row, idx) => (
                    <tr key={row.mobileNo}>
                      <td>{idx + 1}</td>
                      <td>{row?.dpId ?? "N/A"}</td>
                      <td>{row?.closureStatus ?? "N/A"}</td>
                      <td>{row?.name ?? "N/A"}</td>
                      <td>{row?.panNumber ?? "N/A"}</td>
                      <td>{row?.phoneNumber ?? "N/A"}</td>

                      {/* <td>
                        <Button
                          disabled={row.clientCode === "NULL"}
                          onClick={() =>
                            handleApiCalls(
                              `cvlkra/${row.mobileNo}`,
                              "",
                              "KRA Successfull",
                              "KRA failed, Something went wrong"
                            )
                          }
                          variant="success"
                        >
                          Generate Pdf
                        </Button>
                      </td> */}

                      <td
                        className={`${
                          searchData.filter === "COMPLETED" ||
                          searchData.filter === "NONEED"
                            ? "d-none"
                            : ""
                        }`}
                      >
                        <Button
                          onClick={() => StatusButton(row, "COMPLETED")}
                          variant="success"
                        >
                          Complete
                        </Button>
                      </td>
                      <td
                        className={`${
                          searchData.filter === "COMPLETED" ||
                          searchData.filter === "NONEED"
                            ? "d-none"
                            : ""
                        }`}
                      >
                        <Button
                          onClick={() => StatusButton(row, "NONEED")}
                          variant="danger"
                        >
                          No Need
                        </Button>
                      </td>
                      <td>
                        <Button
                          onClick={() => ExportButton(row)}
                          variant="success"
                        >
                          Export
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <p className="no-data-available">No Data Available</p>
              )}
            </Table>
          </Col>
        </Row>
        {/* <Pagination className="px-4">
          {Array(+data?.totalPage ?? 1)
            ?.fill(1)
            ?.map((_, index) => {
              return (
                <Pagination.Item
                  onClick={() =>
                    setSearchData((prev) => ({ ...prev, page: index + 1 }))
                  }
                  key={index + 1}
                  active={index + 1 === searchData.page}
                >
                  {index + 1}
                </Pagination.Item>
              );
            })}
        </Pagination> */}
      </Container>
    </div>
  );
};

export default ClientListingClosure;
