import { React, useState } from "react";
import { useEffect } from "react";
import "./styles.css";
import useApi from "../../hooks/useApi";
import { ToastContainer, toast } from "react-toastify";
import Button from 'react-bootstrap/Button';
import Loader from "../../components/loader";
import PdfPreview from "../../components/pdfViewer";

const PanInfo = ({ data,updateRejectData }) => {
  const { loading, error, postData, fetchData } = useApi();
  const [remark, setRemark] = useState("");
  const [imageData, setImageData] = useState("");

  const [isRejectButtonDisabled, setRejectButtonDisabled] = useState(false)

  // const handleApiCall = async () => {
  //   if (remark?.length > 10) {
  //     try {
  //       const res = await postData(
  //         `reject/upload/${data?.userDetail?.phoneNumber}`,
  //         {
  //           PAN_CARD: remark,
  //         }
  //       );
  //       toast.success("Rejected successfully");
  //     } catch (err) {
  //       toast.error("Something went wrong");
  //     }
  //   }
  // };
  const handleConsole =(e)=>{
    e.preventDefault()
    const newRem = remark ==="" ? "" : `<b>PAN</b>${remark}`
    updateRejectData({ PAN_CARD: newRem });
    setRejectButtonDisabled(true);
    toast.success("Pushed")
  }

  const handleUploadData = async () => {
    if (imageData) {
      try {
        const res = await postData(
          `admin-ekyc/user/upload/document/${data?.userDetail?.phoneNumber}`,
          {
            clientSignature: imageData,
          }
        );
        await toast.success("Uploaded successfully");
        setImageData("");
      } catch (err) {
        toast.error("Something went wrong");
      }
    } else {
      toast.error("Please select doc to upload");
    }
  };

  const onChange = (e) => {
    const files = e.target.files;
    const file = files[0];
    getBase64(file, e.target.name);
  };

  const getBase64 = (file, name) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setImageData(reader.result);
    };
  };

  useEffect(() => {
    const storedRemark = localStorage.getItem("Pan");
    if (storedRemark) {
      setRemark(storedRemark);
    }
  }, []);

  // Save remark to local storage when it changes
  useEffect(() => {
    localStorage.setItem("Pan", remark);
  }, [remark]);
 
  // const signatureValue = data?.rejectionList.find(item => item.startsWith("PAN_CARD:")) || "";
  const signatureValue = (data?.rejectionList || []).find(item => item.startsWith("PAN_CARD:")) || "";
  const inputValue = signatureValue.replace("PAN_CARD:", "") ? signatureValue.replace("PAN_CARD:", "") : remark;

  
  return (
    <>
      <div>
        <Loader open={loading} />
        <ToastContainer />
        <div className="head-container border border-2">
          <div className="reject-container p-2 text-start">
            <label for="floatingInputGroup1">
              <b
                className={`${
                  Boolean(data?.rejectedDocumentDetails?.PAN_CARD)
                    ? "error-text"
                    : ""
                }`}
              >
                Reject Remark
              </b>
            </label>
            <input
              value={inputValue}
              onChange={(e) => setRemark(e.target.value)}
              type="text"
              className="form-control reject-input  border-secondary"
              id="floatingInputGroup1"
            />
          </div>
          <div className="d-flex align-items-center">
        <Button onClick={handleConsole} className="bg-primary" disabled={inputValue == signatureValue.replace("PAN_CARD:", "")}>Reject</Button>{' '}
        </div>
        </div>
        {data?.panData?.pan_pdf ? (
          <PdfPreview base64String={data?.panData?.pan_pdf ?? ""} />
        ) : (
        ""
        )}
      </div>
    </>
  );
};

export { PanInfo };
