import React, { useState, useEffect ,useRef} from "react";
import Button from "react-bootstrap/Button";
import { ToastContainer, toast } from "react-toastify";
import axios from 'axios';

const Nominee3 = ({ data, updateRejectData }) => {
  const [remark, setRemark] = useState("");
  const [isRejectButtonDisabled, setRejectButtonDisabled] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = async (user) => {
    if (selectedFile) {
      const authToken = sessionStorage.getItem("authToken");
  
      // Create a FileReader to read the file
      const reader = new FileReader();
  
      // Event listener for when the file has been read
      reader.onload = async () => {
        // Get the base64 encoded string
        let base64EncodedFile = reader.result;
  
        // Remove the prefix (e.g., data:image/png;base64,)
        if (base64EncodedFile.includes(',')) {
          base64EncodedFile = base64EncodedFile.split(',')[1];
        }
  
        // Create the payload as a JSON object
        const payload = {
          nomineeThree: base64EncodedFile,
        };
  
        try {
          // Make the POST request with JSON payload
          const response = await axios.post(`https://ekyc.finovo.tech:5556/api/v1/rekyc/nominee/upload/image`, payload, {
            headers: {
              'Authorization': authToken,
              'Content-Type': 'application/json',
              'xuserid': user,
            },
          });
  
          toast.success("Upload Successfully");
          fileInputRef.current.value = '';
          console.log('File uploaded successfully:', response.data);
        } catch (error) {
          toast.error('Error uploading file');
          console.error('Error uploading file:', error);
        }
      };
  
      // Read the selected file as a data URL (base64)
      reader.readAsDataURL(selectedFile);
    } else {
      console.error('No file selected');
    }
  };

  const handleConsole = () => {
    const newRem = remark === "" ? "" : `<b>THIRD NOMINEE PROOF</b>${remark}`;
    updateRejectData({ NOMINEE_THREE: newRem });
    setRejectButtonDisabled(true);
    toast.success("Pushed");
  };

 

  useEffect(() => {
    const storedRemark = localStorage.getItem("nominee3");
    if (storedRemark) {
      setRemark(storedRemark);
    }
  }, []);

  // Save remark to local storage when it changes
  useEffect(() => {
    localStorage.setItem("nominee3", remark);
  }, [remark]);

  // const signatureValue = data?.rejectionList.find(item => item.startsWith("NOMINEE_THREE:")) || "";
  const signatureValue = (data?.rejectionList || []).find(item => item.startsWith("NOMINEE_THREE:")) || "";
  const inputValue = signatureValue.replace("NOMINEE_THREE:", "") ? signatureValue.replace("NOMINEE_THREE:", "") : remark;
  const eee=()=>{
    console.log(inputValue);
  }
  return (
    <div className="border border-2">
      <ToastContainer />
      <div className="head-container">
        <div className="reject-container p-2 text-start">
          <label for="floatingInputGroup1">
            <b>Reject Remark</b>
          </label>
          <input
           value={inputValue}
           onChange={(e) => setRemark(e.target.value)}
            type="text"
            className="form-control reject-input  border-secondary"
            id="floatingInputGroup1"
          />
        </div>
        <div className="d-flex align-items-center">
          <Button onClick={handleConsole} disabled={inputValue == signatureValue.replace("NOMINEE_THREE:", "")}>
            Reject
          </Button>{" "}
        </div>
        <div className="input-file p-2 text-start gap-2">
          <label for="floatingInputGroup1">
            <b>
              Nominee proof (only png.jpg.pdf) <br />
              (file should be less than 4 MB)
            </b>
          </label>
          <input
          ref={fileInputRef}
            type="file" id="floatingInputGroup1" accept=".png,.jpg,.pdf" onChange={handleFileChange} />
          <button className="btn btn-info m-auto" onClick={() =>
            handleUpload(data.backOfficeDetails.userId)
          }>Upload</button>
        </div>      </div>
      <div className="document-container">
        <div className="panImg-container">
          <img
            src={`data:image/png;base64,${
              data?.nominee && data?.nominee?.length > 1
                ? data?.nominee[1]?.proofOfAddress
                : ""
            }`}
            alt=""
            width="100%"
            height="100%"
          />
        </div>
      </div>
    </div>
  );
};

export default Nominee3;
