import React, { useState, useEffect } from "react";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "./styles.css";
import UserAdmin from "../userAdmin";

function UserLogin() {
  const [isLoading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const [valid, setValid] = useState(false);
  const [userInfo, setUserInfo] = useState({
    username: "",
    userpassword: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await fetch(
        "https://ekyc.finovo.tech:5556/api/v1/login/authenticate",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username: userInfo.username,
            userpassword: userInfo.userpassword,
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        sessionStorage.setItem("authToken", data.token);
        sessionStorage.setItem("admin", data.name);
        setMsg(data.msg);
        setValid(true);
      } else {
        setMsg("Login Failed");
        setValid(false);
      }
    } catch (error) {
      console.error(error);
      setMsg("An error occurred during login");
      setValid(false);
    } finally {
      setLoading(false);
    }
  };
  if (valid === false) {
    return (
      <>
        <div>
          <div className="client-main-container">
            <Form className="client-login border p-5 rounded-5 shadow-lg">
              <p className="fw-bold fs-1 border-bottom">
                Admin Login{" "}
                {isLoading && <Spinner animation="border" variant="info" />}
              </p>
              {/* Display the message */}
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <p className="text-start fw-semibold m-0 fs-3">User Name</p>
                <Form.Control
                  className="bg-secondary text-white mb-2 rounded-pill"
                  type="text"
                  name="username"
                  value={userInfo.username}
                  onChange={handleChange}
                  placeholder="Enter your name"
                />
                <p className="fw-semibold text-start m-0 fs-3">User Password</p>
                <Form.Control
                  className="bg-secondary text-white rounded-pill"
                  type="password"
                  name="userpassword"
                  value={userInfo.userpassword}
                  onChange={handleChange}
                  placeholder="Enter your password"
                />
              </Form.Group>
              {msg && <p className="text-danger">{msg}</p>}{" "}
              <Button
                variant="primary"
                className="text-center w-100 rounded-pill"
                type="submit"
                onClick={handleLogin}
              >
                 
                Log in
              </Button>
            
            </Form>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <UserAdmin />
      </>
    );
  }
}

export default UserLogin;
